import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyAQZE9HqgL3H646PBAWJuNSnOgL7rqmFRg",
  authDomain: "todolist-2d595.firebaseapp.com",
  projectId: "todolist-2d595",
  storageBucket: "todolist-2d595.appspot.com",
  messagingSenderId: "456727700167",
  appId: "1:456727700167:web:cbd3235d98bc0d8bd554e2"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => signInWithPopup(auth, provider);
